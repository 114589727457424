'use client';
import * as React from 'react';
import { BaseSection } from '@/app/base-ui';
import ListActions from './components/ListActions';
import { Box, Grid, useTheme } from '@mui/material';
import ContentSlider from './components/ContentSlider';
import ExpertChannel from './components/ExpertChannel';
import PopularPosts from './components/PopularPosts';
import Curious from './components/Curious';
import MainBanner from './components/MainBanner';
import usePosts from '@/app/hooks/useListPosts';
import { PostTypeConfig } from '@/app/constants/typedef';
import InfiniteScroll from '../../MUI/InfiniteScroll';
import { Loading } from '../../Posts/Post/components/Loading';
import { motion, useScroll, useMotionValueEvent } from 'framer-motion';
import { usePathname } from 'next/navigation';

const maxPostCanFetch = 20;

const LoadingPost = () => {
    return (
        <Box sx={{ paddingX: { mobile: 5, tablet: 12.5 } }}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    mobile={12}
                    tablet={6}
                >
                    {Loading.LoadMoreArticlesHome()}
                </Grid>
                <Grid
                    item
                    mobile={12}
                    tablet={6}
                >
                    {Loading.LoadMoreArticlesHome()}{' '}
                </Grid>
            </Grid>
        </Box>
    );
};
export default function Banners() {
    const theme = useTheme();
    const { posts, isLoading, totalPosts, hasNext, loadMore, refresh } = usePosts({ selectedButton: undefined, perPage: 10, type: PostTypeConfig.fetchForHome });
    const canFetchMore = React.useMemo(() => posts.length < maxPostCanFetch && posts.length < totalPosts, [posts]);
    const currentPathName = React.useRef('/');
    const theFirstMount = React.useRef(false);
    const ref = React.useRef(null);
    const scrollContainerRef = React.useRef(null);
    const [showCurious, setShowCurious] = React.useState(false);
    const pathName = usePathname();

    

    React.useEffect(() => {
        if (theFirstMount.current && pathName === currentPathName.current) {
            refresh();
        }
        theFirstMount.current = true
    }, [pathName]);

    const { scrollYProgress } = useScroll({
        target: scrollContainerRef,
        offset: ['start end', 'end end'],
    });

    const handleScrollChange = (value: any) => {
        // if (isDevelopment) {
        //     // console.log('first Detect FAQ --- Can fetch:', !canFetchMore, 'value', value, 'dataLength', posts.length, 'total', totalPosts);
        // }

        if (value === 1 && !canFetchMore && !isLoading) {
            setShowCurious(true);
        }
    };

    useMotionValueEvent(scrollYProgress, 'change', handleScrollChange);

    React.useEffect(() => {
        handleScrollChange(scrollYProgress.get());
    }, [posts.length, scrollYProgress, canFetchMore, isLoading]);

    return (
        <BaseSection
            ref={ref}
            sx={{ borderRadius: '0px', background: theme.palette.background.default, height: '100%', width: '100%', flex: '1 1 auto' }}
        >
            <InfiniteScroll
                isLoading={isLoading}
                fetchNextPage={canFetchMore ? loadMore : async () => {}}
                hasNextPage={hasNext && canFetchMore}
                showLoading={true}
                loadingMessage={undefined}
                className="overflow-auto"
                style={{ display: 'flex', flexDirection: 'column' }}
                ref={scrollContainerRef}
            >
                <Box sx={{ position: 'relative', mb: { laptop: 18, tablet: 18, mobile: 0 } }}>
                    <MainBanner />
                    <Box
                        sx={{
                            display: { laptop: 'flex', tablet: 'flex', mobile: 'none' },
                            borderRadius: '16px',
                            justifyContent: 'center',
                            zIndex: 99,
                            width: { laptop: 'calc(100% - 100px)', tablet: 'calc(100% - 100px)', mobile: '' },
                            backdropFilter: 'blur(10px)',
                            background: 'rgba(255, 255, 255, 0.90)',
                            position: 'absolute',
                            left: 'calc(50%)',
                            transform: 'translateX(-50%)',
                            bottom: '-50px',
                            boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)',
                        }}
                    >
                        <ListActions />
                    </Box>
                </Box>

                <Box sx={{ background: theme.palette.background.default, boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)', mb: 5, display: { tablet: 'none', laptop: 'none', mobile: '' } }}>
                    <ListActions />
                </Box>
                <Box sx={{ paddingX: { mobile: '20px', tablet: '50px' } }}>
                    {/* check if data qualification > 0 ==> show ? null */}
                    {/* <Box sx={{ mb: 10 }}>
        <ContentSlider /> // hide until the next stage
      </Box> */}
                    {/* check if data qualification > 0 ==> show ? null */}
                    {/* hide for stage 1 */}
                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', mb: 10 }}>
                        <ExpertChannel />
                    </Box> */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 10 }}>
                        <PopularPosts posts={posts} />
                    </Box>
                </Box>
            </InfiniteScroll>
            {showCurious ? (
                <Box sx={{ paddingX: { mobile: '20px', tablet: '50px' } }}>
                    <motion.div
                        initial={{ x: '-100%', opacity: 0.5 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.4 }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 10 }}>
                            <Curious />
                        </Box>
                    </motion.div>
                </Box>
            ) : null}
        </BaseSection>
    );
}
